.project-card {
    background-color: #f9f9f9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
    /* Align content in the center */
    cursor: pointer;
    height: 344px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 24px;
    padding: 24px 16px;
    border-radius: 16px;
    justify-content: end;
    margin-top: 20px;
}

.project-card:hover {
    animation: bounce 0.5s infinite alternate;
}

@keyframes bounce {
    0% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(-20px);
    }
}

.project-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    border-radius: 16px;
    top: 0px;
    left: 0px;
}

.project-card .group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 3;
    text-align: left;
}

.project-card .group .title {
    font-weight: 600;
    font-size: 24px;
    color: white;
    z-index: 2;
}

.project-card .group .text {
    font-size: 18px;
    color: white;
    font-weight: 500;
    max-width: 500px;
    position: relative;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: baseline;
    z-index: -1;
    overflow-y: auto;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    pointer-events: none;
}

.modal-overlay.show {
    opacity: 1;
    pointer-events: auto;
    z-index: 1000;
}

.modal-content {
    background-color: #1E1E1E;
    padding: 0px 16px 8px 16px;
    position: relative;
    width: 100%;
    margin: 2rem 1rem 1rem 1rem;
    border-radius: 24px;
    overflow: auto;
}

.modal-close {
    cursor: pointer;
    border-radius: 25px;
    user-select: none;
    box-sizing: border-box;
    text-align: center;
    font-size: 24px;
    background-color: #18181880;
    color: white;
    position: absolute;
    right: 24px;
    top: 16px;
    line-height: 0;
    width: 36px;
    height: 36px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    margin: 0px !important;
}

.modal-close:hover {
    background-color: rgba(255, 255, 255, 0.344);
    color: #1E1E1E;
}

.center {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.projTitle {
    z-index: 2;
    font-size: 3rem;
    font-weight: 600;
    color: white;
    position: relative;
}

.header {
    padding: 24px 16px;
    height: 80vh;
    justify-content: end !important;
    width: 100%;
    align-self: center;
    max-width: 1920px;
}

.text-header {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.blueFilter {
    width: 100%;
    height: 100%;
    background-color: #18181898;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 16px;
}

.header .group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 3;
}

.header .group .title {
    font-weight: 600;
    font-size: 20px;
    color: white;
    z-index: 2;
}

.header .group .text {
    font-size: 18px;
    color: white;
    font-weight: 500;
    max-width: 500px;
    position: relative;
}

.information {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-top: 24px;
    justify-content: center;
    width: 100%;
}

.project-description {
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.project-description img {
    object-fit: contain;
    border-radius: 16px;
    max-width: 1080px;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
}

.project-description video {
    object-fit: contain;
    border-radius: 16px;
    max-width: 1080px;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
}

.div-description {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    max-width: 768px;
}

.div-description .title {
    font-size: 24px;
    /* Larger font size for the title */
    font-weight: bold;
    width: 256px;
    flex-shrink: 0;
    color: #E9EBF2;
}

/* .div-description .title::after {
    content: '';
    width: 48px;
    height: 4px;
    background-color: #E9EBF2;
    display: block;
    border-radius: 4px;
    margin-top: 4px;
    display: block;
} */

.div-description .description-text {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #90939e;
    gap: 8px;
}

.div-description .points-list {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
}

.div-description .point {
    margin-bottom: 0.5rem;
    /* Space between list items */
}

.center .details {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.center .details>div {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 4px 16px 8px 16px;
    /* border-radius: 16px 0px 16px 16px; */
    color: black;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    line-break: auto;
    max-width: 640px;
    overflow: hidden;
}

.center .details .title {
    color: #E9EBF2;
    font-weight: 600;
    font-size: 1.4rem;
    line-break: strict !important;
}

.center .details .title::after {
    content: '';
    display: block;
    height: 8px;
}

.center .details .text {
    color: #90939e;
    font-size: 18px;
    position: relative;
}

.center .centralVideo,
.center .centralImage {
    width: 468px;
    height: 585px;
    border-radius: 16px;
    align-self: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.center .titleDetails {
    font-weight: bold;
    font-size: 3.4rem;
    background-image: linear-gradient(90deg, #F76162, #E67203);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
    height: min-content;
    width: 100%;
    line-break: normal !important;
}

.center .titleDetails::after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    background-color: #E67203;
    margin-top: 4px;
}

.center .callToAction {
    padding: 24px;
    font-weight: bold;
    color: #E9EBF2;
    width: 100%;
}

.playButton {
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.400);
    padding: 8px 16px;
    width: fit-content;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    right: 24px;
    bottom: 16px;
    z-index: 100;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
}

.playButton:hover {
    background-color: white;
}

.playButton img {
    height: 16px;
    width: 16px;
}

.scroll {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #ffffff;
    z-index: 10;
    font-weight: 100;
    align-self: center;
    margin-top: 8px;
    justify-content: center;
    align-items: center;
}

.case-study-warning {
    z-index: 2;
    position: absolute;
    top: 10px;
    background-color: #f9f9f9;
    border-radius: 16px;
    font-size: 16px;
    padding: 8px 16px;
}

/* Responsive */
@media screen and (max-width: 768px) {
    .information {
        flex-direction: column !important;
        flex-wrap: nowrap;
        align-items: center;
    }

    .centralImage,
    .centralVideo {
        width: 100% !important;
    }

    @media (max-width: 768px) {
        .div-description {
            flex-direction: column;
            /* Stack title and description on smaller screens */
        }
    }
}