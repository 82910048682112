@font-face {
    font-family: 'Avant Garde Light';
    src: url('../fonts/AVGARDD.TTF') format('ttf');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Avant Garde';
    src: url('../fonts/AVGARDDO.TTF') format('ttf');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Avant Garde Italic';
    src: url('../fonts/AVGARDN.TTF') format('ttf');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}