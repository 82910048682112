.menu {
    flex-direction: column;
    display: flex;
    width: fit-content;
    height: fit-content;
    gap: 16px;
    border-radius: 16px;
    color: #000000;
    padding: 12px 0px;
    font-size: 1rem;
}

.menu a {
    position: relative;
    transition: background-image 0.3s ease;
}

.menu a:hover {
    background-image: linear-gradient(90deg, #F76162, #E67203);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
}

.menu a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    /* Adjust this to control the spacing below the text */
    width: 50%;
    height: 1px;
    /* Height of the border */
    background-color: #E67203;
    /* Color of the border */
    border-radius: 2px;
    /* Optional: for smooth rounded corners */
    transform: scaleX(0);
    /* Initially hide the underline */
    transition: transform 0.3s ease;
    /* Smooth transition for the underline */
}

.menu a:hover::after {
    transform: scaleX(1);
    /* Show the underline on hover */
}

.menu .active {
    background-image: linear-gradient(90deg, #000080, #0000FF);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    position: relative;
    /* Important to position the border under the text */
}

.menu .active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    /* Adjust this to control the spacing below the text */
    width: 50%;
    height: 1px;
    /* Height of the border */
    background-color: #0000FF;
    /* Color of the border */
    border-radius: 2px;
    /* Optional: for smooth rounded corners */
    transform: scaleX(1) !important;
}

.leftBar {
    cursor: default;
    margin-bottom: 48px;
    margin-top: 36px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Responsive */
@media screen and (max-width: 768px) {
    .titlePage {
        display: none;
    }

    .menu {
        justify-content: space-evenly;
        flex-direction: row !important;
        font-size: 16px;
        align-self: center;
    }

    .leftBar {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
        margin-bottom: 0px !important;
        align-items: center;
    }

    .lizard {
        width: 24px;
        height: 24px;
    }

    .callToActions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        padding: 0px 8px;
    }

    .buttonAction {
        justify-content: space-evenly;
    }

    .buttonAction .button {
        font-size: 14px;
        padding: 0px;
    }
}