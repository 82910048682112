.bubbleCustom {
    background-color: #E9EBF2;
    border-radius: 0px 8px 8px 8px;
    padding: 8px;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 4px;
    opacity: 0;
    text-wrap: wrap;
    width: fit-content;
    visibility: hidden;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    color: #000000;
}

.bubbleCustom.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
}


.line {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    width: 48px;
    color: #A7A7A7;
    padding: 0px 8px;
    cursor: default;
    transition: all 0.4s ease-in-out;
}

.line .verticalLine {
    width: 5px;
    height: 94%;
    background-color: #F76162;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;
}

.line .verticalLine::before {
    content: ' ';
    width: 16px;
    height: 16px;
    background-color: #F76162;
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: -5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;
}

.bubbleCustom .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.spacer {
    height: 24px;
}

.bubbleCustom .content .master {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.bubbleCustom .content .master .details {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: calc(100vh - 80px);
    height: auto;
    overflow: auto;
    border-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    gap: 24px;
    padding: 24px 16px;
    border-radius: 16px;
    height: auto;
    min-height: 90vh;
}

.bubbleCustom .content .master .details .left {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    gap: 24px;
    padding: 24px 16px;
    flex: 1;
    order: 1;
}

.bubbleCustom .content .master .details .right {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    gap: 24px;
    padding: 24px 16px;
    flex: 1;
    order: 2;
    align-items: end;
    text-align: right;
}

.bubbleCustom .content .master .eit {
    background-image: url('../../assets/img/MasterGraduation.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.bubbleCustom .content .master .non-eit {
    background-image: url('../../assets/img/BachelorGraduation.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



.bubbleCustom .content .master .detailsMaster .franceFlag {
    position: absolute;
    height: 50%;
    background-color: #ffffff64;
    width: 100%;
    top: 50%;
    z-index: 1;
    left: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .franceFlag::before {
    content: ' ';
    position: absolute;
    background-color: #00265420;
    width: 30%;
    height: 100%;
    z-index: 1;
    border-bottom-left-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .franceFlag::after {
    content: ' ';
    position: absolute;
    background-color: #AA151B20;
    width: 37%;
    height: 100%;
    right: 0;
    z-index: 1;
    border-bottom-right-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .italyFlag {
    position: absolute;
    height: 50%;
    background-color: #F1F2F164;
    width: 100%;
    top: 0px;
    z-index: 1;
    left: 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .italyFlag::before {
    content: ' ';
    position: absolute;
    background-color: #02793b64;
    width: 33.33%;
    height: 100%;
    z-index: 1;
    border-top-left-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .italyFlag::after {
    content: ' ';
    position: absolute;
    background-color: #AA151B20;
    width: 33.33%;
    height: 100%;
    right: 0;
    z-index: 1;
    border-top-right-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .polandFlag {
    position: absolute;
    top: 50%;
    background-color: #F1F2F164;
    width: 100%;
    height: 50%;
    left: 0px;
    z-index: 1;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .polandFlag:after {
    content: ' ';
    position: absolute;
    background-color: #AA151B20;
    height: 50%;
    width: 100%;
    bottom: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    z-index: 1;
}


.bubbleCustom .content .master .detailsMaster .spainFlag {
    position: absolute;
    height: 50%;
    width: 100%;
    top: 0px;
    z-index: 1;
    margin: auto;
    display: block;
    left: 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .spainFlag .stripe {
    display: block;
    width: 100%;
}

.bubbleCustom .content .master .detailsMaster .spainFlag .top {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.bubbleCustom .content .master .detailsMaster .spainFlag .red {
    background-color: #AA151B20 !important;
    height: 25% !important;
}

.bubbleCustom .content .master .detailsMaster .spainFlag .yellow {
    background-color: #f1c10020 !important;
    height: 50% !important;
}



.bubbleCustom .content .master .details .title {
    z-index: 2;
    font-size: 3rem;
    font-weight: 600;
    color: white;
}

.bubbleCustom .content .master .details .box .title {
    font-size: 14px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: white;
    color: #1e1e1e;
}

.bubbleCustom .content .master .details .box .title:hover {
    background-color: #e8e8e8;
    font-size: 15px;
}

.bubbleCustom .content .master .details .group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 3;
}

.bubbleCustom .content .master .details .group .title {
    font-weight: 600;
    font-size: 20px;
    color: white;
}

.bubbleCustom .content .master .details .group .text {
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.bubbleCustom .content .master .detailsMaster .title {
    z-index: 2;
    font-size: 3rem;
    font-weight: 600;
    color: white;
}

.bubbleCustom .content .master .detailsMaster .group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 3;
}

.bubbleCustom .content .master .detailsMaster .group .title {
    font-weight: 600;
    font-size: 20px;
    color: white;
}

.bubbleCustom .content .master .detailsMaster .group .text {
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.bubbleCustom .content .master .projects {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
}

.bubbleCustom .content .master .projects .box {
    padding: 0px !important;
    align-items: end;
    width: fit-content;
    gap: 0px;
}

.bubbleCustom .content .master .projects>div {
    padding: 4px 16px 8px 16px;
    border-radius: 16px 4px 16px 4px;
    flex-grow: 1;
    /* color: white; */
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.bubbleCustom .content .master .projects .ups {
    background-color: #64003D;
}

.bubbleCustom .content .master .projects .upm {
    background-color: #0860A8;
}

.bubbleCustom .content .master .projects .wut {
    background-color: #6AB597;
}

.bubbleCustom .content .master .projects .ups>img {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 32px;
}

.bubbleCustom .content .master .projects .upm>img {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 48px;
}

.bubbleCustom .content .master .projects .wut>img {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 48px;
}

.bubbleCustom .content .master .projects .description {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.bubbleCustom .content .master .projects .description .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 4px;
}

.bubbleCustom .content .master .projects .description .text {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
}

.bubbleCustom .content .master .projects .button {
    font-weight: 600;
    font-size: 18px;
    background-color: white;
    padding: 4px 16px;
    width: fit-content;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
}

.bubbleCustom .content .master .projects .button .arrow {
    margin-left: 4px;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateX(-10px);
}

.bubbleCustom .content .master .projects .button:hover .arrow {
    opacity: 1;
    transform: translateX(0);
}

.bubbleCustom .content .master .projects .ups .button {
    color: #64003D;
}

.bubbleCustom .content .master .projects .upm .button {
    color: #0860A8;
}

.bubbleCustom .content .master .projects .wut .button {
    color: #2D3843;
}

.callToAction {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    margin-bottom: 32px;
    position: relative;
}

.callToAction .title {
    font-size: 1.4rem;
    text-align: center;
    position: relative;
}

.callToAction .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
}

.callToAction .buttons .leftButton {
    background-color: #0000FF;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.callToAction .buttons .leftButton:hover {
    background-color: white;
    color: #0000FF;
}

.callToAction .buttons .rightButton {
    background-color: white;
    color: #0000FF;
    font-size: 18px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.callToAction .buttons .rightButton:hover {
    background-color: #0000FF;
    color: white;
}


/* Work Experience */

/* Air Liquide */
.bubbleCustom .content .master .air-liquide {
    background-image: url('../../assets/img/AirLiquideBg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bubbleCustom .content .master .air-liquide .blueFilter {
    width: 100%;
    height: 100%;
    background-color: #00349B80;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 16px;
}

.bubbleCustom .content .master .box .title {
    font-weight: 600;
    cursor: pointer;
}

.activeContributionTitle {
    border-radius: "8px 8px 0px 0px";
    font-size: "15px";
}

.activeContributionText {
    max-height: "140px";
    padding: "8px 16px";
}

.bubbleCustom .content .master .box .text {
    font-size: 18px;
    font-weight: 500;
    color: #9496a1;
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;
    background-color: #181818;
    border-radius: 8px 0px 8px 8px;
    padding: 0px;
    font-size: 16px;
    text-align: right;
    cursor: default;
}

.bubbleCustom .content .master .box .text span {
    /* background-image: linear-gradient(90deg, #F76162, #E67203);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative; */
    color: white;
    font-weight: bold;
}

.gap-8 {
    gap: 8px !important;
}

.m-0 {
    margin: 0px !important;
}

.w-100 {
    width: 100% !important;
}

/* Niipy */
.bubbleCustom .content .master .niipy {
    background-image: url('../../assets/img/Niipy.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bubbleCustom .content .master .niipy .blueFilter {
    width: 100%;
    height: 100%;
    background-color: #3B797D80;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 16px;
}

/* Rosterbook */
.bubbleCustom .content .master .rosterbook {
    background-image: url('../../assets/img/Rosterbook.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bubbleCustom .content .master .rosterbook .blueFilter {
    width: 100%;
    height: 100%;
    background-color: #6A717090;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 16px;
}

/* Selected Work */
.gap-0 {
    gap: 0px !important;
}

.content .title-gallery {
    font-size: 24px;
    font-weight: 600;
    padding: 16px 0px 0px 24px;
}

/* Contacts */
.contacts {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 8px 48px;
    z-index: 100;
}

.contacts>div {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.contacts>div .title {
    /* border-radius: 8px; */
    font-weight: 600;
    color: white;
    /* padding: 4px 16px; */
    width: fit-content;
    position: relative;
}

.contacts>div .groupContact .icon img {
    width: 36px;
    height: 36px;
    position: relative;
    z-index: 4;
}

.contacts>div .groupContact .data {
    font-size: 14px;
    position: relative;
}

.contacts>div .groupContact .data .description {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    margin-bottom: 4px;
}

.contacts>div .groupContact {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.playContact {
    position: relative !important;
    margin-top: 8px !important;
    bottom: 0px !important;
    right: 0px !important;
    background-color: rgba(255, 255, 255, 0.700) !important;
    color: #000000 !important;
}

.playContact:hover {
    background-color: white !important;
}

.filterContacts {
    width: 100%;
    height: 100%;
    background-color: #18181898;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 16px;
}

.white {
    color: white !important;
}

.contentContact {
    background-image: linear-gradient(to right, #fd6827, #a50034);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    gap: 24px;
    padding: 24px 16px;
    border-radius: 16px;
    height: auto;
    min-height: 90vh;
}

/* Responsive */
@media screen and (max-width: 768px) {
    .bubbleCustom .content .master .projects {
        flex-direction: column !important;
    }

    .line {
        display: none;
    }

    .contacts {
        padding: 8px;
    }

    .contacts>div {
        gap: 16px;
    }
}