@font-face {
  font-family: 'Swear-Display-Light';
  src: url('../fonts/Swear-Display-Light.otf') format('otf');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Swear-Display-Thin';
  src: url('../fonts/Swear-Display-Thin.otf') format('otf');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Swear-Display';
  src: url('../fonts/Swear-Display.otf') format('otf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Swear-Display-Medium';
  src: url('../fonts/Swear-Display-Medium.otf') format('otf');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Swear-Display-Black';
  src: url('../fonts/Swear-Display-Black.otf') format('otf');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}