@import url('css/avant-garde.css');

/* Global Styles */
html {
  scroll-behavior: smooth;
}

body {
  /* font-family: 'General Sans', sans-serif; */
  font-family: 'Avant Garde', sans-serif;
  font-size: 12px;
  background-color: #F8F9FA;
  /* max-height: 100vh; */
  /* overflow: hidden; */
}

.App {
  background-color: #F8F9FA;
  padding-left: 8vw;
  padding-right: 8vw;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.5s ease-in-out;
}

.ball {
  background: #91939e12;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out, background-color 0.5s ease-in-out;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.topBar {
  cursor: default;
  margin-bottom: 48px;
  margin-top: 36px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lizard {
  padding: 8px;
  border-radius: 100%;
  background-image: linear-gradient(90deg, #F76162, #E67203);
  transition: transform 0.2s ease-in-out;
}

.textColorChange:hover {
  background-image: linear-gradient(90deg, #08B17B, #33904B);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 24px;
  padding: 24px 0px;
  border-radius: 16px;
  cursor: pointer;
}

.centerHome {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.headerHome {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 0px;
  border-radius: 16px;
  cursor: pointer;
}

.myName {
  display: flex;
  font-size: 4.4rem;
  font-weight: 400;
  align-items: baseline;
  gap: 16px;
  flex-wrap: wrap;
}

.bigMe {
  font-size: 4.4rem;
  letter-spacing: -0.2rem;
  font-weight: 700;
  color: #91939e;
  transition: color 0.5s ease-in-out;
  cursor: default;
}

.grey-text {
  color: #91939e !important;
  transition: color 0.5s ease-in-out;
}

.white-text {
  color: #EAEAEA !important;
  transition: color 0.5s ease-in-out;
}

.black-text {
  color: #000000 !important;
  transition: color 0.5s ease-in-out;
}

.blue {
  background-image: linear-gradient(90deg, #000080, #0000FF) !important;
  transition: opacity 0.5s ease-in-out !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.orange {
  background-image: linear-gradient(90deg, #F76162, #E67203) !important;
  transition: opacity 0.5s ease-in-out;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.funny {
  font-family: 'Swear Display';
  font-weight: bold;
  font-style: italic;
  font-size: 4.4rem;
  background-image: linear-gradient(90deg, #F76162, #E67203);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: default;
  width: fit-content;
  line-height: 80px;
  letter-spacing: 3px;
}

.msc {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 48px;
  font-style: italic;
}

.callToActions {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  text-align: center;
}

.buttonAction {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.buttonAction .button {
  border-radius: 8px;
  padding: 4px 0px;
  font-size: 1.2rem;
  cursor: pointer;
  width: fit-content;
  color: #91939e;
  font-weight: 700;
  position: relative;
  transition: color 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.buttonAction .button:hover {
  color: black;
  transform: scale(1.05);
}

.buttonAction .button:hover+.myMessage {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.myMessage {
  opacity: 0;
  visibility: hidden;
  display: flex;
  /* Ensure the layout is respected */
  transform: translateY(10px);
  /* Optional: slightly move the element down initially */
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  flex-direction: row;
  gap: 8px;
}

.smallProfile {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
}

.bubble {
  background-color: #E9EBF2;
  border-radius: 0px 8px 8px 8px;
  padding: 4px 8px;
  max-width: 80px;
  font-size: 0.8rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: center;
  margin-top: 36px;
  margin-bottom: 16px;
  max-height: 80px;
}

.contacts {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-flow: row wrap;
  text-wrap: wrap;
  line-break: anywhere;
}

.contacts .icon {
  width: 36px;
  height: 36px;
}

.copyright {
  font-size: 12px;
  color: #90939e;
  text-align: center;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

a {
  text-decoration: none;
  color: #000000;
}

/* Ask Me section */

.AskToMe {
  background-color: #F8F9FA;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 0px 0px 8vw;
  transition: background-color 0.5s ease-in-out;
}

.content {
  display: flex;
  padding: 0px 8px;
  flex-direction: row;
  align-self: center;
  gap: 16px;
  flex: 1;
  overflow: hidden;
  height: fit-content;
  width: 100%;
  text-wrap: wrap;
}

.titlePage {
  font-family: 'Swear Display';
  font-weight: bold;
  font-style: italic;
  font-size: 3.4rem;
  background-image: linear-gradient(90deg, #000080, #0000FF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: default;
  line-height: 80px;
  align-self: center;
}

.chatSpace {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  flex: 1;
  overflow-y: auto;
  height: 90%;
}

.myMessageChat {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.profilePicChat {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
}

.bubbleChat {
  background-color: #E9EBF2;
  border-radius: 0px 8px 8px 8px;
  padding: 4px 8px;
  font-size: 16px;
  text-wrap: wrap;
  max-width: 90%;
  width: fit-content;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
}

.bubbleChat span {
  z-index: 10;
  position: relative;
}

.messageSide {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}

.messageSide .nameSender {
  font-weight: bold;
  width: fit-content;
  margin-bottom: 4px;
  z-index: 10;
  transition: all 0.5s ease-in-out;
}

.userMessage {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
  margin-left: auto;
  text-align: right;
  position: relative;
  font-weight: bold;
}

.userMessage .title {
  font-weight: bold;
  color: #90939e;
}

.userMessage .questions {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-end;
}

.userMessage .questions .question {
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: 16px;
  border: 1px solid #0000FF;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  width: fit-content;
  font-weight: bold;
  color: #90939e;
}

.userMessage .questions .question:hover {
  color: white;
  background-color: #0000FF;
}

.conversation {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.conversation .userMessage {
  gap: 0px;
}

.bubbleUser {
  background-color: #0101ED;
  color: white;
  border-radius: 8px 0px 8px 8px;
  padding: 8px 8px;
  font-size: 1rem;
  text-wrap: wrap;
  width: fit-content;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}


.shimmer>div {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
  border-radius: 20px;
  height: 40px;
}


@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.shimmer {
  width: 64%;
  height: 50px;
  border-radius: 0px 16px 16px 16px;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #e9ebf2;
}

.shimmer .longLine {
  width: 100%;
}

.shimmer .shortLine {
  width: 40%;
}

/* Selected Work */

.galleryTitle {
  padding: 20px 0px;
}

.main {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 projects per row */
  gap: 20px;
  padding: 20px;
}

.underline {
  text-decoration: underline !important;
}

/* Contacts */
.rightPanel {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.rightPanel .title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

/* .rightPanel .contacts {
  background-image: url('/src/assets/img/office.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 24px;
  padding: 24px 16px;
  border-radius: 16px;
  height: fit-content;
} */

.rightPanel .contacts .group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  width: fit-content;
  z-index: 3;
  color: white;
}

.rightPanel .contacts .group .title {
  font-weight: 500;
  font-size: 16px;
  padding: 0px !important;
}

.rightPanel .contacts .group .link {
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  background-color: rgba(255, 255, 255, 0.199);
  font-weight: 500;
}

.rightPanel .contacts .group .link:hover {
  color: white;
  background-color: #0000FF;
}

.rightPanel .contacts .callToAction {
  width: fit-content !important;
  margin-top: 24px;
  margin-bottom: 0px !important;
  z-index: 3;
}

.rightPanel .contacts .callToAction .title {
  font-weight: 400 !important;
}

.rightPanel .contacts .callToAction {
  gap: 8px !important;
}

.rightPanel .text {
  color: #797988;
  font-size: 14px;
  text-align: center;
  padding-left: 8vw;
  padding-right: 8vw;
}

.blueFilter {
  width: 100%;
  height: 100%;
  background-color: #18181898;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 16px;
}

.contentContacts {
  padding: 0px 16px;
}

.mh-16 {
  margin: 20px 16px 0px 16px;
}

.contactApp {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.contactApp .sidebar {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 16px 8px 8px;
}

.contactApp .sidebar .menuElement {
  font-size: 16px;
  color: grey;
  font-weight: bold;
  padding: 8px 36px 8px 8px;
  border-radius: 0px 16px 16px 0px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.profileDetails .name {
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.profileDetails .role {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.profileDetails .role:hover {
  text-decoration: underline;
}

.contactApp .sidebar .menuElement:hover {
  background-color: #F8F9FA;
  color: black;
}

.contactApp .sidebar .active {
  background-color: #0000FF;
  color: #F8F9FA;
  transition: all 0.4s ease-in-out;
}

.messageArea {
  font-family: 'Avant Garde', sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 16px 80px 8px 8px;
  resize: none;
  border: none;
  outline: none;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease-in-out;
  line-height: 30px;
  position: relative;
  width: calc(100% - 80px);
}

.textarea-container {
  position: relative;
  display: inline-block;
  width: min(493px, 100%);
}

.copyButton {
  position: absolute;
  top: 88px;
  right: 0px;
  background-color: #F76162;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
  font-weight: bold;
}

.profileInfo {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 8px 80px 8px 8px;
  background: white;
  width: calc(100% - 80px);
  border-bottom: 2px solid #91939e;
  border-radius: 8px 8px 0px 0px;
}

.profileInfo .profileDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  font-weight: bold;
}

.smooth-width {
  display: inline-block;
  transition: width 0.5s ease-in-out;
  white-space: nowrap;
}

/* Reponsive */
@media screen and (max-width: 768px) {
  .bigMe {
    font-size: 40px;
    line-height: 44px;
  }

  .topBar {
    margin-bottom: 0px !important;
    margin-top: 8px !important;
  }

  .myName {
    gap: 8px;
  }

  .myName,
  .funny,
  .msc {
    font-size: 36px;
    line-height: 44px;
  }

  .funny {
    letter-spacing: 0.8px;
  }

  .App,
  .AskToMe {
    padding-left: 8px;
    padding-right: 8px;
  }

  .header {
    padding: 8px 8px 80px 8px !important;
    gap: 24px !important;
  }

  .headerHome {
    gap: 8px !important;
  }

  .callToActions {
    gap: 4px;
    padding: 8px 0px;
  }

  .buttonAction .button {
    font-size: 18px;
    padding: 4px 16px;
  }

  .displayMobile {
    flex-direction: column !important;
  }

  .profilePicChat {
    height: 24px;
    width: 24px;
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px !important;
    padding: 8px !important;
  }

  .callToAction {
    margin-top: 24px;
  }

  .callToAction .title {
    font-size: 1.4rem !important;
  }

  .contactApp .sidebar h1 {
    font-size: 16px;
  }

  .contactApp .sidebar .menuElement {
    padding: 8px 0px 8px 4px;
    font-size: 14px;
  }

  .mh-16 {
    margin: 0px;
  }

  .messageArea,
  .profileInfo {
    width: calc(100% - 150px);
  }

  .copyButton {
    right: 70px;
  }

  .role {
    font-size: 14px;
  }
}

/* Styles for screens that are at least 1024px wide (desktops and larger) */
/* @media screen and (min-width: 1024px) {
  body {
    font-size: 20px;
    background-color: #e0e0e0;
  }
} */

/* Styles for screens smaller than 480px wide (mobile phones) */
/* @media screen and (max-width: 480px) {
  body {
    font-size: 14px;
    background-color: #ffffff;
  }
} */